import { gql } from 'react-apollo';

const customerDealerGroupQuery = gql`
  query customerDealerGroupList($groupName: String, $size: Int, $page: Int) {
    customerDealerGroupList(groupName: $groupName, size: $size, page: $page) {
      groupId
      groupName
      description
      createdAt
      updatedAt
    }
  }
`;

export default customerDealerGroupQuery;

import { gql } from 'react-apollo';

const createDealerGroupMutation = gql`
  mutation createCustomerGroup(
    $groupName: String!
    $groupDescription: String!
  ) {
    createCustomerGroup(
      input: {
        customerGroup: {
          groupName: $groupName
          groupDescription: $groupDescription
        }
      }
    ) {
      success
      msg
      errors
    }
  }
`;

export default createDealerGroupMutation;

import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Button, Message, Confirm } from 'semantic-ui-react';

import withPagination from './WithPagination';
import Search from '../views/GroupManager/Search';
import List from '../views/GroupManager/List';
import customerDealerGroupQuery from '../graphql/customerDealerGroupQuery';
import { setSearch } from '../data/searchField/actions';
import { setPage } from '../data/page/actions';
import { graphql } from 'react-apollo';
import createDealerGroupMutation from '../graphql/createDealerGroupMutation';
import updateDealerGroupMutation from '../graphql/updateDealerGroupMutation';
import LoadingDataEnhancer from './LoadingData';
import { toNumber } from 'lodash';
import GroupManagerForm from '../views/GroupManager/Form';
import deleteDealerGroupMutation from '../graphql/deleteDealerGroupMutation';

const defaultDealerGroup = {
  groupId: 0,
  groupName: '',
  groupDescription: ''
};

const messageSuccess = {
  Create: 'Successfully Created New Dealer Group',
  Update: 'Successfully Updated Dealer Group',
  Delete: 'Successfully Deleted Dealer Group'
};

const GroupAction = {
  Create: 'Create',
  Update: 'Update',
  Delete: 'Delete'
};

class GroupManager extends Component {
  state = {
    groupName: '',
    error: '',
    selectedDealerGroup: null,
    isModalOpen: false,
    formError: null,
    formSubmitting: null,
    flashMessage: null,
    isDeleteConfirmationOpen: false,
    isNegativeFlashMsg: false
  };

  groupNameSearchQuery = event => {
    const { groupName } = event.target;
    if (groupName.value.length > 100) {
      this.setState({
        error: 'Group name is too long (maximum 100 characters)'
      });
      return false;
    }
    const variableArguments = {
      groupName: groupName.value
    };
    this.props.data.refetch({
      ...variableArguments
    });

    this.setState({ error: '' });
    this.props.setSearchFields(variableArguments);
    this.props.setPage({});
  };

  onSelectDealerGroup = (action, dealerGroup) => {
    this.setState({
      selectedDealerGroup: dealerGroup,
      flashMessage: null
    });

    switch (action) {
      case 'Edit':
        this.setState({
          isModalOpen: true
        });
        break;
      case GroupAction.Delete:
        this.setState({
          isDeleteConfirmationOpen: true
        });
        break;
      default:
        this.setState({
          selectedDealerGroup: null
        });
    }
  };

  onFormSubmitHandler = async values => {
    const {
      createDealerGroupMutation,
      updateDealerGroupMutation,
      deleteDealerGroupMutation
    } = this.props;
    this.setState({
      formSubmitting: true
    });
    const action = values['action'];
    try {
      switch (action) {
        case GroupAction.Create:
          await this.onExcuteMutationDealerGroup(
            createDealerGroupMutation,
            'createCustomerGroup',
            values,
            action
          );
          break;
        case GroupAction.Update:
          await this.onExcuteMutationDealerGroup(
            updateDealerGroupMutation,
            'updateCustomerGroup',
            values,
            action
          );
          break;
        case GroupAction.Delete:
          await this.onExcuteMutationDealerGroup(
            deleteDealerGroupMutation,
            'deleteCustomerGroup',
            this.state.selectedDealerGroup,
            action
          );
          break;
      }
    } catch (error) {
      this.onFailedSubmitDealerGroup(
        Array.isArray(error) ? error : error.message,
        action
      );
    }
  };

  onFormCloseHandler = () => {
    this.setState({
      isModalOpen: false,
      formError: null,
      formSubmitting: null
    });
  };

  onClickNewDealerGroup = () => {
    this.setState({
      selectedDealerGroup: defaultDealerGroup,
      isModalOpen: true
    });
  };

  successSubmitDealerGroup = action => {
    this.setState({
      formSubmitting: false,
      formError: null,
      flashMessage: messageSuccess[action],
      isNegativeFlashMsg: false,
      isDeleteConfirmationOpen: false
    });
  };

  onFailedSubmitDealerGroup = (errorMessage, action) => {
    if (action === GroupAction.Delete) {
      this.setState({
        flashMessage: errorMessage,
        isDeleteConfirmationOpen: false,
        isNegativeFlashMsg: true
      });
    } else {
      this.setState({
        formSubmitting: false,
        formError: errorMessage
      });
    }
  };

  getDealerGroupMutationActionInput = values => {
    return {
      groupId: toNumber(values['groupId']),
      groupName: values['groupName'],
      groupDescription: values['groupDescription']
    };
  };

  onExcuteMutationDealerGroup = async (
    mutation,
    mutationResultName,
    values,
    action
  ) => {
    let input = this.getDealerGroupMutationActionInput(values, action);
    await mutation({
      variables: { ...input }
    })
      .then(({ data }) => {
        const { success, msg } = data[mutationResultName];

        if (success) {
          this.successSubmitDealerGroup(action);
          this.onFormCloseHandler();
          this.props.setPage({});
          this.props.data.refetch();
        } else {
          this.onFailedSubmitDealerGroup(msg, action);
        }
      })
      .catch(error => {
        this.onFailedSubmitDealerGroup(
          Array.isArray(error) ? error : error.message,
          action
        );
      });
  };

  onCancelDelete = () => {
    this.setState({
      isDeleteConfirmationOpen: false
    });
  };

  render() {
    const {
      data: { customerDealerGroupList },
      searchFields
    } = this.props;

    const {
      error,
      selectedDealerGroup,
      formSubmitting,
      isModalOpen,
      formError,
      flashMessage,
      isNegativeFlashMsg
    } = this.state;

    return (
      <div>
        {/*Success message*/}
        {flashMessage && (
          <Message
            onDismiss={this.handleMessageDismiss}
            content={flashMessage}
            positive
            negative={isNegativeFlashMsg}
          />
        )}
        <b>Group Manager</b>
        <br />
        <br />
        <Button color="green" onClick={this.onClickNewDealerGroup} inverted>
          Create Dealer Group
        </Button>
        <br />
        <br />
        <Search
          onSubmit={this.groupNameSearchQuery}
          searchFields={searchFields}
          searchOptions={['groupName']}
        />
        {error && <span style={{ color: 'red' }}>{error}</span>}
        <br />
        <br />
        <List
          onSelectDealerGroup={this.onSelectDealerGroup}
          customerDealerGroupList={customerDealerGroupList}
        />
        {isModalOpen && (
          <GroupManagerForm
            isModalOpen={isModalOpen}
            onCloseHandler={this.onFormCloseHandler}
            onSubmitHandler={this.onFormSubmitHandler}
            selectedDealerGroup={selectedDealerGroup}
            error={formError}
            submitting={formSubmitting}
          />
        )}
        <Confirm
          open={this.state.isDeleteConfirmationOpen}
          onCancel={this.onCancelDelete}
          onConfirm={() =>
            this.onFormSubmitHandler({ action: GroupAction.Delete })
          }
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchFields: fields => {
    dispatch(setSearch(fields));
  },
  setPage: fields => {
    dispatch(setPage());
  }
});

const mapStateToProps = state => {
  const {
    data: { searchFields }
  } = state;
  return {
    searchFields
  };
};

export default compose(
  withRouter,
  withPagination(customerDealerGroupQuery, 'customerDealerGroupList'),
  graphql(createDealerGroupMutation, { name: 'createDealerGroupMutation' }),
  graphql(updateDealerGroupMutation, { name: 'updateDealerGroupMutation' }),
  graphql(deleteDealerGroupMutation, { name: 'deleteDealerGroupMutation' }),
  LoadingDataEnhancer,
  connect(mapStateToProps, mapDispatchToProps)
)(GroupManager);

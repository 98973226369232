import React, { Component } from 'react';
import { compose } from 'recompose';
import { withRouter } from 'react-router-dom';
import client from '../../apollo';
import { get, toNumber } from 'lodash';
import { Button, Confirm, Message } from 'semantic-ui-react';
import { graphql } from 'react-apollo';
import { connect } from 'react-redux';

import Form from './GroupException/Form';
import GroupExceptionList from './List';
import customerDealerGroupQuery from '../../graphql/customerDealerGroupQuery';
import { setPage } from '../../data/page/actions';
import withPagination from '../../containers/WithPagination';
import autoCancellationExceptionListQuery from '../../graphql/autoCancellationExceptionListQuery';
import createAutoCancellationExceptionMutation from '../../graphql/createAutoCancellationExceptionMutation';
import updateAutoCancellationExceptionMutation from '../../graphql/updateAutoCancellationExceptionMutation';
import deleteAutoCancellationExceptionMutation from '../../graphql/deleteAutoCancellationExceptionMutation';

const defaultGroupException = {
  id: 0,
  groupException: '',
  gracePeriod: '',
  exceptionEndTime: '',
  createdBy: '',
  createdAt: '',
  lastUpdatedBy: '',
  lastUpdatedAt: ''
};

const fieldsToCompare = ['gracePeriod', 'exceptionEndTime'];
class GroupExceptions extends Component {
  state = {
    isModalOpen: false,
    formSubmitting: false,
    action: 'Create',
    selectedGroupException: null,
    isDeleteConfirmation: false,
    flashMessage: null,
    formError: null,
    groups: [],
    isNegativeFlashMessage: false
  };

  createNewGroupException = () => {
    this.setState({
      isModalOpen: true,
      selectedGroupException: defaultGroupException
    });
  };

  onFormCloseHandler = () => {
    this.setState({
      isModalOpen: false,
      selectedGroupException: null,
      formError: null,
      formSubmitting: null
    });
  };

  isChange = (values, selectedGroupException, fieldsToCompare) => {
    for (const field of fieldsToCompare) {
      if (values[field] !== selectedGroupException[field]) {
        return true;
      }
    }
    return false;
  };

  onFormSubmitHandler = async values => {
    const {
      createAutoCancellationExceptionMutation,
      updateAutoCancellationExceptionMutation
    } = this.props;
    const { selectedGroupException } = this.state;
    this.setState({
      formSubmitting: true
    });
    const action = values['action'];
    switch (action) {
      case 'Create':
        await this.onExcuteMutationGroupException(
          createAutoCancellationExceptionMutation,
          'createAutoCancellationException',
          values,
          action
        );
        break;
      case 'Update':
        if (this.isChange(values, selectedGroupException, fieldsToCompare)) {
          await this.onExcuteMutationGroupException(
            updateAutoCancellationExceptionMutation,
            'updateAutoCancellationException',
            values,
            action
          );
        } else {
          // unchanged
          this.onFormCloseHandler();
        }
        break;
    }
  };

  onFailedSubmitGroupException = (action, errorMessage) => {
    if (action === 'Delete') {
      this.setState({
        isDeleteConfirmation: false,
        flashMessage: `Unsuccessfully Deleted Group Exception with error: ${errorMessage}`,
        isNegativeFlashMessage: true
      });
    } else {
      this.setState({
        formSubmitting: false,
        formError: errorMessage
      });
    }
  };

  onExcuteMutationGroupException = async (
    mutation,
    mutationResultName,
    values,
    action
  ) => {
    let input = this.getCreateExpiredVraGracePeriodMutationInput(
      action,
      values
    );
    await mutation({
      variables: { ...input }
    })
      .then(({ data }) => {
        const { success, msg } = data[mutationResultName];
        if (success) {
          this.successSubmitGroupException(action);
          this.onFormCloseHandler();
          if (action === 'Delete') {
            this.setState({
              isDeleteConfirmation: false
            });
          }
          this.props.setPage({});
          this.props.data.refetch();
        } else {
          this.onFailedSubmitGroupException(action, msg);
        }
      })
      .catch(error => {
        this.onFailedSubmitGroupException(
          action,
          Array.isArray(error) ? error : error.message
        );
      });
  };

  getCreateExpiredVraGracePeriodMutationInput = (action, values) => {
    return {
      exceptionType: 'GROUP',
      exceptionSourceId: toNumber(values['groupId']),
      gracePeriod: toNumber(values['gracePeriod']),
      exceptionEndTime: values['exceptionEndTime'],
      id: values['id'] ? toNumber(values['id']) : 0
    };
  };

  successSubmitGroupException = action => {
    this.setState({
      formSubmitting: false,
      formError: null,
      flashMessage: `Successfully ${action}d Group Exception.`
    });
  };

  onSelectGroupException = (action, groupException) => {
    this.setState({
      selectedGroupException: groupException,
      flashMessage: null,
      isNegativeFlashMessage: false
    });

    switch (action) {
      case 'Edit':
        this.setState({
          isModalOpen: true
        });
        break;
      case 'Delete':
        this.setState({
          isDeleteConfirmation: true
        });
        break;
      default:
        this.setState({
          selectedGroupException: null
        });
    }
  };

  handleDeleteCancel = () => {
    this.setState({ isDeleteConfirmation: false });
  };

  handleDeleteConfirm = async () => {
    const {
      selectedGroupException: { id }
    } = this.state;
    const { deleteAutoCancellationExceptionMutation } = this.props;
    const action = 'Delete';
    try {
      await this.onExcuteMutationGroupException(
        deleteAutoCancellationExceptionMutation,
        'deleteAutoCancellationException',
        { id: id },
        action
      );
    } catch (error) {
      this.onFailedSubmitGroupException(
        action,
        Array.isArray(error) ? error : error.message
      );
    }
  };

  groupNameQuery = () => {
    client
      .query({
        query: customerDealerGroupQuery,
        variables: {
          page: 1,
          size: 200
        }
      })
      .then(({ data }) => {
        this.setState({
          groups: get(data, 'customerDealerGroupList')
        });
      })
      .catch(error => {
        this.setState({ groups: [] });
      });
  };

  handleMessageDismiss = () => {
    this.setState({ flashMessage: null });
  };

  componentWillMount() {
    this.groupNameQuery();
  }

  render() {
    const {
      data: { autoCancellationExceptionList }
    } = this.props;
    const {
      isModalOpen,
      formSubmitting,
      action,
      selectedGroupException,
      isDeleteConfirmation,
      flashMessage,
      groups,
      formError,
      isNegativeFlashMessage
    } = this.state;

    return (
      <div>
        {flashMessage && (
          <Message
            onDismiss={this.handleMessageDismiss}
            content={flashMessage}
            positive
            negative={isNegativeFlashMessage}
          />
        )}
        <Button inverted color="green" onClick={this.createNewGroupException}>
          New Group Exception
        </Button>
        <br />
        <br />
        <GroupExceptionList
          onSelectException={this.onSelectGroupException}
          exceptions={autoCancellationExceptionList}
          exceptionType={'GROUP'}
        />
        {isModalOpen && (
          <Form
            isModalOpen={isModalOpen}
            onCloseHandler={this.onFormCloseHandler}
            onSubmitHandler={this.onFormSubmitHandler}
            submitting={formSubmitting}
            groups={groups}
            groupException={selectedGroupException}
            error={formError}
          />
        )}
        <Confirm
          open={isDeleteConfirmation}
          onCancel={this.handleDeleteCancel}
          onConfirm={this.handleDeleteConfirm}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setPage: fields => {
    dispatch(setPage());
  }
});

const mapStateToProps = state => {
  const {
    data: { user }
  } = state;
  return {
    user: user
  };
};

export default compose(
  withRouter,
  withPagination(
    autoCancellationExceptionListQuery,
    'autoCancellationExceptionList',
    { exceptionTypes: 'GROUP' }
  ),
  graphql(createAutoCancellationExceptionMutation, {
    name: 'createAutoCancellationExceptionMutation'
  }),
  graphql(updateAutoCancellationExceptionMutation, {
    name: 'updateAutoCancellationExceptionMutation'
  }),
  graphql(deleteAutoCancellationExceptionMutation, {
    name: 'deleteAutoCancellationExceptionMutation'
  }),
  connect(mapStateToProps, mapDispatchToProps)
)(GroupExceptions);

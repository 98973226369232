import React, { Component } from 'react';
import Validator from 'validatorjs';
import { isEmpty, toNumber } from 'lodash';
import { Form, Button, Modal, Icon, Message } from 'semantic-ui-react';
const colorAlert = '#9f3a38';

const titleFormName = {
  Create: 'Create Dealer Group',
  Update: 'Edit Dealer Group'
};

class GroupManagerForm extends Component {
  state = {
    groupId: '',
    groupName: '',
    groupDescription: '',
    action: 'Create',
    errors: {}
  };

  componentDidMount() {
    const { selectedDealerGroup } = this.props;
    const { groupId, groupName, description } = selectedDealerGroup;

    this.setState({
      groupId: toNumber(groupId),
      groupName: groupName,
      groupDescription: description,
      action: groupId === 0 ? 'Create' : 'Update'
    });
  }

  submitForm = () => {
    this.setState({
      errors: {}
    });

    const { onSubmitHandler } = this.props;

    const { groupId, groupName, groupDescription, action, errors } = this.state;
    const validationRules = {
      groupName: 'required|max:100',
      groupDescription: 'max:2000'
    };

    const validation = new Validator(this.state, validationRules);

    if (validation.fails()) {
      let newErrors = {
        ...validation.errors.errors
      };
      this.setState({ errors: newErrors });
      return false;
    } else if (!isEmpty(errors.groupName)) {
      return false;
    } else {
      onSubmitHandler({
        groupId: parseInt(groupId, 10),
        groupName: groupName,
        groupDescription: groupDescription || '',
        action: action
      });
    }
  };

  render() {
    const { groupName, groupDescription, action, errors } = this.state;
    const { isModalOpen, onCloseHandler, error, submitting } = this.props;

    const onChangeHandler = field => event => {
      this.setState({ [field]: event.target.value });
    };

    const description = !isEmpty(groupDescription) ? groupDescription : '';

    const MAX_LENGTH_GROUP_NAME = 100;
    const MAX_LENGTH_GROUP_DESCRIPTION = 2000;
    const countRestOfGroupName = groupName
      ? MAX_LENGTH_GROUP_NAME - groupName.length
      : MAX_LENGTH_GROUP_NAME;

    const countRestOfGroupDescription = groupDescription
      ? MAX_LENGTH_GROUP_DESCRIPTION - groupDescription.length
      : MAX_LENGTH_GROUP_DESCRIPTION;

    return (
      <Modal
        closeIcon
        open={isModalOpen}
        onClose={onCloseHandler}
        closeOnEscape={true}
        closeOnRootNodeClick={false}
      >
        <Modal.Header>{titleFormName[action]}</Modal.Header>
        <Modal.Content image>
          <Modal.Description>
            {error && (
              <Message negative>
                <p>{error}</p>
              </Message>
            )}
            <Form>
              <Form.Group widths="equal">
                <Form.Field required error={!isEmpty(errors.groupName)}>
                  <label>Name</label>
                  <input
                    name="groupName"
                    value={groupName}
                    onChange={onChangeHandler('groupName').bind(this)}
                    maxLength={MAX_LENGTH_GROUP_NAME}
                    placeholder="Input your group name"
                  />
                  <span style={{ color: colorAlert }}>
                    Remaining characters: {countRestOfGroupName}
                  </span>
                  {errors.groupName && <br />}
                  {errors.groupName && (
                    <span style={{ color: 'red' }}>{errors.groupName}</span>
                  )}
                </Form.Field>
              </Form.Group>
              <Form.Group widths="equal">
                <Form.Field>
                  <label>Description</label>
                  <textarea
                    name="groupDescription"
                    value={description}
                    onChange={onChangeHandler('groupDescription').bind(this)}
                    maxLength={MAX_LENGTH_GROUP_DESCRIPTION}
                    placeholder="Add a brief description of dealer group"
                  />
                  <span style={{ color: colorAlert }}>
                    Remaining characters: {countRestOfGroupDescription}
                  </span>
                  {errors.groupDescription && <br />}
                  {errors.groupDescription && (
                    <span style={{ color: 'red' }}>
                      {errors.groupDescription}
                    </span>
                  )}
                </Form.Field>
              </Form.Group>
            </Form>
          </Modal.Description>
        </Modal.Content>
        <Modal.Actions>
          {submitting && (
            <Button loading positive>
              Submitting...
            </Button>
          )}
          {!submitting && !this.state.id && (
            <Button color="green" onClick={this.submitForm} inverted>
              <Icon name="checkmark" />
              {action}
            </Button>
          )}
          <Button onClick={onCloseHandler}>Cancel</Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default GroupManagerForm;

import { gql } from 'react-apollo';

const updateDealerGroupMutation = gql`
  mutation updateCustomerGroup(
    $groupId: Int!
    $groupName: String!
    $groupDescription: String!
  ) {
    updateCustomerGroup(
      input: {
        customerGroup: {
          groupId: $groupId
          groupName: $groupName
          groupDescription: $groupDescription
        }
      }
    ) {
      success
      msg
      errors
    }
  }
`;

export default updateDealerGroupMutation;

import gql from 'graphql-tag';

const deleteDealerGroupMutation = gql`
  mutation deleteCustomerGroup($groupId: ID!) {
    deleteCustomerGroup(input: { id: $groupId }) {
      success
      msg
    }
  }
`;

export default deleteDealerGroupMutation;
